import { Button, Flex, Placeholder, Link, Table, TableBody, TableCell, TableHead, TableRow, Text } from '@aws-amplify/ui-react';
import React from 'react';
import PageContent from '../components/PageContent';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useCustomers } from '../state/CustomerQueries';

const Customers = () => {
    const navigate = useNavigate();
    const { isLoading, data: customers } = useCustomers();

    return (
        <PageContent title="Customers" headerActions={<Button variation="primary" onClick={() => navigate('new')}>Add Customer</Button>}>
            <Flex direction="column">
                <Placeholder isLoaded={!isLoading}/>
                <Placeholder isLoaded={!isLoading}/>
                <Placeholder isLoaded={!isLoading}/>
                <Placeholder isLoaded={!isLoading}/>

                {!!customers && customers.length && 
                    <Table size="small" highlightOnHover={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Name</TableCell>
                                <TableCell as="th">Contact</TableCell>
                                <TableCell as="th">Address</TableCell>
                                <TableCell as="th">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {customers.map(c => 
                            <TableRow key={c.ID}>
                                <TableCell><Link as={RouterLink} to={c.ID}>{c.Name}</Link></TableCell>
                                <TableCell>{c.Contact}</TableCell>
                                <TableCell>
                                    <Flex direction="column" gap='0.1em'>
                                        <Text size="small">{c.Address1}</Text>
                                        {!!c.Address2 && <Text size="small">{c.Address2}</Text>}
                                        <Text size="small">{c.City}, {c.State} {c.ZIP}</Text>
                                    </Flex>
                                </TableCell>
                                <TableCell>
                                    {c.Status}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                }
            </Flex>
        </PageContent>
    )
}

export default Customers;