import { Button, Grid, Image } from "@aws-amplify/ui-react";
import React, {useRef} from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import h_logo from "../assetts/images/logos/horizontal.svg"
let style = {
    width: "100vw",
    height: "100px",
    backgroundColor: "transparent",
    position: "fixed",
    top: 0,
    zIndex: 2,
}

const singInBtn = {
    color: 'white',
    background: "rgb(100, 95, 170)",
    background: "linear-gradient(39deg, rgba(100, 95, 170, 1) 30%, rgba(0, 114, 188, 1) 60%, rgba(120, 182, 228, 1) 100%)"
}

const contactBtn = {
    color: "white",
    background: "#FF6804",
    borderRadius: "15px"
}

const Navbar = ({contactRef}) => {

    let listener = null
    const [scrollState, setScrollState] = useState("top")

    const navigate = useNavigate()

    useEffect(() => {
        listener = document.addEventListener("scroll", e => {
            var scrolled = document.scrollingElement.scrollTop
            if (scrolled >= 50) {
                if (scrollState !== "amir") {
                    setScrollState("amir")
                    style = { ...style, backgroundColor: "white" }
                }
            } else {
                if (scrollState !== "top") {
                    setScrollState("top")
                    style = { ...style, backgroundColor: "transparent" }
                }
            }
        })
        return () => {
            document.removeEventListener("scroll", listener)
        }
    }, [scrollState])

    console.log(style)
    return (
        <div style={style}>
            <div style={{ width: '75vw', height: '100%', margin: 'auto', display: 'flex', alignItems: "center" }}>
                <div className="nav-logo-container">
                    <Image
                        columnStart="1"
                        columnEnd="3"
                        src={h_logo}
                        height="75px"
                    />
                </div>
            
                <div className="nav-btn-container">
                    <Button style={contactBtn}  variation="primary" onClick={e => contactRef.current.scrollIntoView()}>Contact Us</Button>
                    <Button style={singInBtn} onClick={() => navigate('/portal')} >Sign in</Button>
                </div>
            </div>

        </div>
    )
}

export default Navbar;