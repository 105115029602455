/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b4be1385-8b60-45d5-828a-8acd99c11779",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_JCJZnYxen",
    "aws_user_pools_web_client_id": "4mctk5mbqg204rttj004tsusib",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "portal-20211219135931-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://dh8q4ky15x7vu.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "portalApi",
            "endpoint": "https://iuxr9rq9b9.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://gk5qrluif3.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "portal-prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
