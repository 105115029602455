import React, { Fragment } from 'react';
import { Flex, Link, View, Text } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router';

const Breadcrumbs = ({ parents, leaf }) => {
    const navigate = useNavigate();

    return (
        <View>
            <Flex gap="0.5em">
                {parents.map((p, i) => <Fragment key={i}><Link fontSize='0.8em' onClick={() => navigate(p.path)}>{p.name}</Link><Text fontSize='0.8em'>&gt;</Text></Fragment>)}
                <Text fontSize='0.8em' key={parents.length}>{leaf}</Text>
            </Flex>
        </View>
    )
};

export default Breadcrumbs;