import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Home from './pages/Home';

Amplify.configure(awsExports);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/portal/*" element={<App/>} />
        </Routes>
        <Outlet/>
      </BrowserRouter>
    </AmplifyProvider>
  </React.StrictMode>
);
