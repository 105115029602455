import React, { useContext, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Flex, Placeholder, Table, TableHead, TableBody, TableRow, TableCell, Link, Button } from '@aws-amplify/ui-react';
import { FaSync } from 'react-icons/fa';
import { UserContext } from '../state/UserContext';
import PageContent from '../components/PageContent';

const UsersPage = ({ onUserSelected }) => {
    const { identities, refreshIdentities } = useContext(UserContext);
    const [isLoaded, setLoaded] = useState(false);
    
    useEffect(() => {
        setLoaded(!!identities.length);
    }, [identities]); 

    return (
        <PageContent title="Users" headerActions={<Button onClick={refreshIdentities}><FaSync/></Button>}>
            <Flex direction="column" width="100%" textAlign="left">
                <Placeholder size="large" isLoaded={isLoaded} />
                <Placeholder size="large" isLoaded={isLoaded} />
                <Placeholder size="large" isLoaded={isLoaded} />
                <Placeholder size="large" isLoaded={isLoaded} />
                <Placeholder size="large" isLoaded={isLoaded} />
                {isLoaded && 
                    <Table size="small" highlightOnHover={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Name</TableCell>
                                <TableCell as="th">Email</TableCell>
                                <TableCell as="th">Verified</TableCell>
                                <TableCell as="th">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {identities.map((u) => 
                                <TableRow key={u.Username}>
                                    <TableCell><Link onClick={() => onUserSelected(u)}>{u.name}</Link></TableCell>
                                    <TableCell>{u.email}</TableCell>
                                    <TableCell>{capitalize(u.email_verified)}</TableCell>
                                    <TableCell>{u.Enabled ? 'Enabled' : 'Disabled'}</TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                }
            </Flex>
        </PageContent>
    )
}

export default UsersPage;