import { Card, Divider, Flex, Heading, Text, View } from '@aws-amplify/ui-react';
import React from 'react';
import Breadcrumbs from './Breadcrumbs';

const PageContent = ({ children, breadcrumbs, title, subtitle, headerActions }) => {
    return (
        <Flex direction="column">
            {!!breadcrumbs && <Breadcrumbs parents={breadcrumbs} leaf={title}/>}
            <Card variation="elevated">
                <View>
                    <Flex justifyContent="space-between">
                        <View direction="column">
                            <Heading level={3}>{title}</Heading>
                            {!!subtitle && <Text>{subtitle}</Text>}
                        </View>
                        <Flex justifyContent="center" direction="column">
                            {!!headerActions && headerActions}
                        </Flex>
                    </Flex>
                    <Divider size="small" style={{margin: '1rem 0'}} />
                    {children}
                </View>
            </Card>
        </Flex>
    )
}

export default PageContent;