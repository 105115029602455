import { Button, Loader } from '@aws-amplify/ui-react';
import { omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

const LoadingButton = ({ children, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    let unmount = useRef(false);

    const onClick = async (e) => {
        setIsLoading(true);
        try {
            await props.onClick(e);
        } catch(e) {
            console.log('error', e)
        }

        if(!unmount.current) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        return function() {
            unmount.current = true;
        }
    }, []);

    return (
        <Button {...omit(props, ['isLoading', 'onClick'])} isLoading={isLoading} onClick={onClick} >
            {isLoading && <Loader style={{ position: 'absolute' }} size="small"/> }
             <div style={{visibility: isLoading ? 'hidden' : 'visible'}}>{children}</div>
        </Button>
    );
}

LoadingButton.propTypes = Button.propTypes;

export default LoadingButton;