import { createContext } from "react";
import { API, Auth } from 'aws-amplify';
import { omit } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";

const queryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 60000,
    refetchOnMount: true
};


export const UserContext = createContext();

const UserContextProvider = ({user, children}) => {
    const queryClient = useQueryClient();
    const {data: userMeta} = useQuery('me', () => API.get('portalApi', '/me'), {
        ...queryOptions, 
        placeholderData: {
            userMeta: {
                Company: '',
                PhoneNumber: '',
                ID: ''
            }
        }
    });

    const updateUserMeta = (meta) => {
        queryClient.setQueryData('me', meta);
    }

    const {data: identities} = useQuery('identities', async () => API.get('AdminQueries', '/listUsers', {
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          } 
    }), {
        ...queryOptions, 
        placeholderData: {Users: []},
        select: (users) => users.Users.reduce((p, u) => {
            return [...p, {
                ...omit(u, ['Attributes']),
                ...u.Attributes.reduce((p, a) => ({...p, [a.Name]: a.Value}), {})
            }];
        }, [])
    });
    
    const refreshIdentities = () => queryClient.invalidateQueries('identities');

    return (
        <UserContext.Provider value={{identities, refreshIdentities, user, userMeta, updateUserMeta}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;