import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
import CustomerLicenses from './CustomerLicenses';
import Customers from './Customers';
import NewCustomerPage from './NewCustomer';

const CustomersIndex = () => {
    
    return (
        <>
            <Routes>
                <Route path="/" element={<Customers/>} />
                <Route path="new" element={<NewCustomerPage/>} />
                <Route path=":customerId" element={<CustomerDetails/>}/>
                <Route path=":customerId/:productId" element={<CustomerLicenses/>}/>
            </Routes>
            <Outlet />
        </>
    )
}

export default CustomersIndex;