import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Flex, Heading, Placeholder, SelectField, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@aws-amplify/ui-react';
import { FaTrash } from 'react-icons/fa';
import { API, Auth } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router';
import { find, pull } from 'lodash';
import { UserContext } from '../state/UserContext';
import PageContent from '../components/PageContent';
import LoadingButton from '../components/LoadingButton';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCustomers } from '../state/CustomerQueries';

const queryConfig = {
    refetchOnWindowFocus: false,
    staleTime: 60000,
    refetchOnMount: true
};

const UserDetails = () => {
    const queryClient = useQueryClient();
    const { updateUserMeta, identities } = useContext(UserContext);
    const { userId } = useParams();
    const user = find(identities, {Username: userId});
    const { data: userMeta } = useQuery(['user', userId], async () => API.get('portalApi', `/users/${userId}`));
    const { data: customers } = useCustomers();

    const [newGroup, setNewGroup] = useState('');
    const [companyID, setCompanyID] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const apiConfig = async () => ({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    });

    const { data: groups } = useQuery(['userGroups'], async () => API.get('AdminQueries', '/listGroups', await apiConfig()), {
        select: (allGroups) => allGroups.Groups.reduce((list, g) => [...list, g.GroupName], []),
        ...queryConfig
    });

    const { data: userGroups, isLoading: groupsLoading } = useQuery(['user', userId, 'groups'], async () => API.get('AdminQueries', `/listGroupsForUser?username=${user.Username}`, await apiConfig()), {
        select: (result) => {
            return result.Groups.reduce((list, g) => [...list, g.GroupName], [])
        },
        enabled: !!user && !!groups,
        ...queryConfig
    })

    useEffect(() => {
        if(userMeta && userMeta.CustomerID)
            setCompanyID(userMeta.CustomerID);
        if(userMeta && userMeta.PhoneNumber)
            setPhone(userMeta.PhoneNumber);
    }, [userMeta]);

    const { mutateAsync: removeUserFromGroup } = useMutation(async (groupname) => await API.post('AdminQueries', '/removeUserFromGroup', {
        ...await apiConfig(),
        body: {
            username: user.Username,
            groupname
        }
    }), {
        onSuccess: (_, groupname) => {
            queryClient.setQueryData(['user', userId, 'groups'], {Groups: pull(userGroups, groupname).map(g => ({GroupName: g}))});
            // queryClient.invalidateQueries(['user', userId, 'groups']);
        }
    });

    const { mutateAsync: addUserGroup } = useMutation(async () => API.post('AdminQueries', '/addUserToGroup', {
        ...await apiConfig(),
        body: {
            username: user.Username,
            groupname: newGroup
        }
    }), {
        onSuccess: () => {
            queryClient.setQueryData(['user', userId, 'groups'], {Groups: [...userGroups, newGroup].map(g => ({GroupName: g}))});
            // queryClient.invalidateQueries(['user', userId, 'groups']);
            setNewGroup('');
        }
    });

    const { mutateAsync: toggleUserState } = useMutation(async () => API.post('AdminQueries', '/disableUser', {
        ...await apiConfig(),
        body: {
            username: user.Username
        }
    }), {
        onSuccess: () => {
            navigate("/users");
        }
    });

    const { mutateAsync: updateUser } = useMutation(async () => API.put('portalApi', `/users/${userId}`, {
        body: {
            Email: user.email,
            Name: user.name,
            CustomerID: companyID,
            Company: find(customers, {ID: companyID}).Name,
            PhoneNumber: phone
        }
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries(['customers', companyID, 'users']);
            
            if(userMeta) {
                queryClient.invalidateQueries(['customers', userMeta.CustomerID, 'users']);
            }

            const data = userMeta || {};
            if (!userMeta || userId === userMeta.ID) { 
                updateUserMeta({
                    ...data,
                    Email: user.email,
                    Name: user.name,
                    CustomerID: companyID,
                    Company: find(customers, {ID: companyID}).Name,
                    PhoneNumber: phone
                });
            }
        }
    });

    const normalizePhone = (value, previousValue) => {
        // return nothing if no value
        if (!value) return value; 
      
        // only allows 0-9 inputs
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length; 
      
        if (!previousValue || value.length > previousValue.length) {
      
          // returns: "x", "xx", "xxx"
          if (cvLength < 4) return currentValue; 
      
          // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
          if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 
      
          // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
          return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
        }
        return value;
      };

    if(!user) {
        return <PageContent>
            <Flex direction='column'>
                <Placeholder />
                <Placeholder />
                <Placeholder />
            </Flex>
        </PageContent>
    }

    return (
        <PageContent 
            title={user.name} 
            subtitle={user.email} 
            breadcrumbs={[{ name: 'Users', path: '/users' }]}
            headerActions={
                <Button onClick={toggleUserState} size="small" variation="primary" backgroundColor={user.Enabled ? 'darkred' : 'green'}>
                    {user.Enabled ? 'Disable User' : 'Enable User'}
                </Button>}>
            
            <Flex>
            <Flex direction="column" style={{flex: 1}}>
                <Heading level={5}>Groups</Heading>
                <Placeholder isLoaded={!groupsLoading} />
                <Placeholder isLoaded={!groupsLoading} />
                <Placeholder isLoaded={!groupsLoading} />
                <Placeholder isLoaded={!groupsLoading} />
                {!!userGroups && 
                    <Flex direction="column">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Name</TableCell>
                                <TableCell as="th">Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userGroups.map(g => <TableRow key={g}>
                                <TableCell>{g}</TableCell>
                                <TableCell>
                                    <LoadingButton variation="link" onClick={() => removeUserFromGroup(g)}><FaTrash/></LoadingButton>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                    <Flex>
                        <SelectField 
                            flex={1} 
                            label="newGroup" 
                            labelHidden={true} 
                            placeholder="Select group"
                            value={newGroup}
                            onChange={(e) => setNewGroup(e.target.value)}>
                            {groups.map(g => <option key={g} value={g}>{g}</option>)}
                        </SelectField>
                        <LoadingButton size="small" onClick={addUserGroup} variation="primary">Add Group</LoadingButton>
                    </Flex>
                    </Flex>
            }
            </Flex>
            <Divider orientation="vertical" size="small" />
                <Flex direction="column" flex="1" justifyContent="space-between">
                    <Flex direction="column">
                        <SelectField 
                            flex={1} 
                            label="Company" 
                            placeholder="Select Company"
                            value={companyID}
                            onChange={(e) => setCompanyID(e.target.value)}>
                            {customers && customers.map(c => <option key={c.ID} value={c.ID}>{c.Name}</option>)}
                        </SelectField>
                        <TextField label="Phone Number" placeholder="Enter phone number" value={phone} onChange={(e) => setPhone(normalizePhone(e.target.value, phone))} />
                    </Flex>
                    <LoadingButton onClick={updateUser} variation="primary">
                            Update User Details
                    </LoadingButton>
                </Flex>
            </Flex>
        </PageContent>
    );
}

export default UserDetails;