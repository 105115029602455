import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import NewProductPage from './NewProduct';
import ProductsPage from './Products';

const ProductsIndex = () => {
    
    return (
        <>
            <Routes>
                <Route index element={<ProductsPage/>}/>
                <Route path="new" element={<NewProductPage/>}/>
                <Route path=":productId" element={<NewProductPage/>}/>
            </Routes>
            <Outlet />
        </>
    )
}

export default ProductsIndex;