import { Button, Divider, Flex, Heading, Link, Placeholder, SelectField, Table, TableBody, TableCell, TableHead, TableRow, Text} from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PageContent from '../components/PageContent';
import { useAddAgreementMutation, useCustomers, useLicenseAgreements } from '../state/CustomerQueries';
import { find } from 'lodash';
import { useProducts } from '../state/ProductQueries';
import { useQuery, useQueryClient } from 'react-query';
import { API } from 'aws-amplify';

const CustomerDetails = () => {
    const queryClient = useQueryClient();
    const { customerId } = useParams();
    const { isLoading: customersLoading, data: customer } = useCustomers(customerId)

    const [newAgreement, setNewAgreement] = useState();
    const addAgreement = useAddAgreementMutation(queryClient, customerId);

    const { isLoading: productsLoading, data: products } = useProducts();
    const {isLoading, data: agreements} = useLicenseAgreements(customerId);

    const productById = (ID) => find(products, {ID}) || {};

    const { data: users, isLoading: usersLoading } = useQuery(['customers', customerId, 'users'], () => API.get('portalApi', `/customers/${customerId}/users`), {
        refetchOnWindowFocus: false,
        staleTime: 60000,
        refetchOnMount: true
    });

    if(isLoading || productsLoading || customersLoading || usersLoading) {
        return (
            <PageContent title={customer && customer.Name} breadcrumbs={[{ name: 'Customers', path: '../../customers'}]}>
                <Flex direction="column">
                    <Placeholder isLoaded={!!customer} />
                    <Placeholder isLoaded={!!customer} />
                    <Placeholder isLoaded={!!customer} />
                    <Placeholder isLoaded={!!customer} />
                </Flex>
            </PageContent>
        );
    }

    return (
        <>
        {customer && 
            <PageContent title={customer.Name} breadcrumbs={[{ name: 'Customers', path: '../../customers'}]}>
                <Flex>
                    <Flex direction="column" flex={1}> 
                        <Heading level={5}>Assigned Products</Heading>
                        <Flex flex={1}>
                        {!!agreements.length && 
                            <Table size="small" highlightOnHover={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell as="th">Name</TableCell>
                                        <TableCell as="th">SKU</TableCell>
                                        <TableCell as="th">Licenses</TableCell>
                                        <TableCell as="th"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {agreements.map(a => 
                                        <TableRow key={a.ProductID}>
                                            <TableCell>{productById(a.ProductID).Name}</TableCell>
                                            <TableCell>{productById(a.ProductID).SKU.toUpperCase()}</TableCell>
                                            <TableCell>
                                                <Text size="small">{a.ActiveLicenses} / {a.ProvisionedLicenses}</Text>
                                            </TableCell>
                                            <TableCell><Link as={RouterLink} to={a.ProductID}>Manage</Link></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        }
                        </Flex>
                        <Flex>
                            <SelectField flex={1} labelHidden={true} onChange={(e) => setNewAgreement(e.target.value)} value={newAgreement} placeholder="Select Product">
                                {products.map(p => <option key={p.ID} value={p.ID}>{p.Name}</option>)}
                            </SelectField>
                            <Button size="small" variation="primary" onClick={() => addAgreement.mutate(newAgreement)}>Add Agreement</Button>
                        </Flex>
                    </Flex>
                    <Divider size="small" orientation="vertical"/>
                    <Flex direction="column" flex={1}> 
                        <Heading level={5}>Assigned Users</Heading>
                        <Table size="small" highlightOnHover={true}>
                            <TableHead>
                                <TableRow>
                                    <TableCell as="th">Name</TableCell>
                                    <TableCell as="th">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users && users.map(u => (
                                    <TableRow key={u.ID}>
                                        <TableCell><Link as={RouterLink} to={`/portal/users/${u.ID}`}>{u.Name}</Link></TableCell>
                                        <TableCell><Link>{u.Email}</Link></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Flex>
                </Flex>
            </PageContent>
        }
        </>
    )
};

export default CustomerDetails;