import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Flex, Heading, Link, Text, withAuthenticator } from '@aws-amplify/ui-react';
import { Link as RouterLink, Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom'
import UsersIndex from './pages/UsersIndex';
import UserContextProvider, { UserContext } from './state/UserContext';
import ProductsIndex from './pages/ProductsIndex';
import CustomersIndex from './pages/CustomersIndex';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient();

const App = ({user, signOut}) => {

  const [active, setActive] = useState('/products');
  const location = useLocation();
  

  useEffect(() => {
    setActive(location.pathname)
    
  }, [location]);

  const menuClassName = (path) => {
    return active.startsWith('/portal/' + path) ? 'menu-link active' : 'menu-link';
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider user={user}>
        <div className="app">
          <header className="app-header">
            <div className="logo">Variable Portal</div>
            <nav className="app-nav">
              
            </nav>
            <div style={{textAlign: 'start'}}>
              <Heading level={6}>{user.attributes.name}</Heading>
              <UserContext.Consumer>
                {({userMeta}) => <Text fontSize="0.85rem" variation="tertiary">{userMeta.Company}</Text>}
              </UserContext.Consumer>
            </div>
            <Button onClick={signOut} style={{ alignSelf: 'flex-end' }}>Sign Out</Button>
          </header> 
          <div className="main">
            <aside className="side-bar">
              <Flex direction="column" gap="0" textAlign='start'>
                <Heading className="menu-heading" level={6}>Products</Heading>
                <Link className={menuClassName('products')} as={RouterLink} to="products">Product List</Link>
                <Link className={menuClassName('licenses')} as={RouterLink} to="licenses">Licenses</Link>
                  
                <Heading className="menu-heading" level={6}>Customers</Heading>
                <Link className={menuClassName('customers')} as={RouterLink} to="customers">Manage Customers</Link>
                
                <Heading className="menu-heading" level={6}>Users</Heading>
                <Link className={menuClassName('users')} as={RouterLink} to="users">Manage Users</Link>
                <Link className={menuClassName('addUser')} as={RouterLink} to="addUser">Add User</Link>
              </Flex>
            </aside>
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Navigate to="products" />} />
                <Route path="products/*" element={<ProductsIndex/>}/>
                <Route path="licenses" element={<div>Licenses</div>} />
                <Route path="users/*" element={<UsersIndex />} />
                <Route path="addUser" element={<div>Add User</div>} />
                <Route path="customers/*" element={<CustomersIndex />} />
              </Routes>
              <Outlet/>
            </div>
          </div>
        </div>
      </UserContextProvider>
      <ReactQueryDevtools initialIsOpen={true}/>
    </QueryClientProvider>
  );
}

export default withAuthenticator(App);