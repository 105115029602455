import { Button, Flex, Heading, Image, Text, View, TextField, TextAreaField } from '@aws-amplify/ui-react';
import React, {useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import sosaLogo from '../assetts/images/logos/sosa_logo.png'
import victoryLogo from '../assetts/images/logos/victory_logo.png'
import Navbar from '../components/Navbar.jsx';
import ServiceDetail from '../components/ServiceDetail';
import whiteLogo from '../assetts/images/logos/white.svg'
import blackLogo from '../assetts/images/logos/black.svg'

const contactBtn = {
    color: "white",
    background: "#FF6804",
    borderRadius: "15px",
    width: '250px',
    height: '55px',
    fontSize: "27px",
    textAlign: "center"
}


const Home = () => {
    const navigate = useNavigate();
    const contactRef = useRef();
    return (
        <View style={{ position: 'absolute', top: 0, height: '100vh', width: '100%' }}>
            <Navbar contactRef={contactRef}/>
            <View>
                <div className='hero-image'>
                    <div className="hero-text" style={{ width: '70%' }}>
                        <Text color="white" fontWeight="bold">
                        Modern Software Development & Systems Integration                        
                        </Text>
                        <Button style={contactBtn} onClick={() => contactRef.current.scrollIntoView()}>Contact Us</Button>
                    </div>
                </div>
            </View>

            <View className="detail-container">
                <div className='detail-section' >
                    <div className='detail-header'>
                        <div style={{ width: 'fit-content', margin: 'auto', textAlign: 'center' }}>
                            <Image height="45%" margin="auto" src={sosaLogo} />
                            <Text fontWeight="bold" fontSize="25px">SOSA&trade; Aligned In-Band System Management</Text>
                        </div>
                    </div>
                    <div className='detail-section-desc'>
                        <ServiceDetail name="Chassis Manager"
                            desc="RMCP-based Agent providing out-of-the-box In-Band System Management capabilities for Chassis Managers."
                        />
                        <ServiceDetail name="Plug-in Card"
                            desc="Extensible plugin-based framework that enables In-Band System Management for any plugin card capable of running Linux."
                        />
                        <ServiceDetail name="Generic Module" 
                            desc="In-Band System Management Agents for other Modules."
                        />
                        <ServiceDetail name="Custom Hardware Integration"
                            desc="Variable Software specializes in customizing System Management capabilities to leverage the unique capabilities provided by your platform."
                        />
                    </div>
                </div>
            </View>

            <View className="detail-container" style={{ background: "lightgrey" }}>
                <div className='detail-section victory'>
                    <div className='detail-section-desc'>
                        <ServiceDetail name="Services" 
                            desc="Management Interfaces compliant with the VICTORY Standard Specifications."
                        />
                        <ServiceDetail name="Network Configuration Management" 
                            desc="Tools for managing and configuring complex network topologies using standard protocols."
                        />
                        <ServiceDetail name="Custom Hardware Integration"
                            desc="Variable Software has more than 20 years of combined system management and system integration experience"
                        />
                    </div>
                    <div className='detail-header'>
                        <div style={{ width: 'fit-content', margin: 'auto', textAlign: 'center' }}>
                            <Image height="45%" margin="auto" src={victoryLogo} />
                            <Text fontWeight="bold" fontSize="25px">VICTORY Management Interfaces</Text>
                            <Text fontStyle="italic" fontSize="20px">Coming Soon</Text>
                        </div>
                    </div>
                </div>
            </View>
            {/* <View style={{ textAlign: 'center', marginTop: '100px' }}>
                <Heading level={1} fontWeight="bold">Contact Us!</Heading>
                <div style={{ width: '30%', margin: 'auto', marginTop: '25px', textAlign: 'left' }}>
                    <TextField
                        label="Name"
                        placeholder='James Kirk'
                    />
                    <br />
                    <TextField
                        label="Email"
                        placeholder='jamestkirk@enterprise.com'
                    />
                    <br />
                    <TextAreaField
                        label="Message"
                        rows="5"
                        wrap="wrap"
                    />
                    <br />
                    <Button style={{ ...contactBtn, width: '100%' }} isFullWidth size='large' variation='primary'>Send Message</Button>
                </div>
            </View> */}
            <div className='contact' ref={contactRef}>
                <Heading level={1} color="#FF6804" fontWeight="bold">Contact Us!</Heading>
                <a href="mailto:info@variablesw.com">info@variablesw.com</a>
                <br/>
                
            </div>
            <div style={{width: '75%', margin: 'auto', marginTop: '50px'}}>
                <Text fontStyle="italic" fontSize="20px" color="grey">SOSA&trade; and logo design and The Open Group Certifiaction Mark&trade; are trademarks of The Open Group in the United States and other countries.</Text>
                </div>
            <footer style={{ marginTop: '100px', height: '250px', width: '100%', backgroundColor: '#0072BC' }}>
                <div style={{ width: '75vw', height: '100%', margin: 'auto', display: 'flex', alignItems: "center" }}>
                    <div style={{width: 'fit-content', margin: 'auto'}}>
                        <Image
                            columnStart="1"
                            columnEnd="3"
                            src={whiteLogo}
                            height="100px"
                        />
                    </div>
                </div>
            </footer>

        </View >
    );
}

export default Home;