import { filter, find } from "lodash";
import { useMutation, useQuery } from "react-query";
import { API } from 'aws-amplify';

const queryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 60000,
    refetchOnMount: true
};

export const useCustomers = (customerId) => useQuery(['customers'], () => API.get('portalApi', '/customers'), { 
    ...queryOptions,
    select: (customers) => {
        if(customerId) return find(customers, {ID: customerId});
        return customers;
    }
 });

export const useLicenseAgreements = (customerId, productId) => useQuery(['licenseAgreements', customerId], () => API.get('portalApi', `/customers/${customerId}/licenseAgreements`), { 
    ...queryOptions,
    select: (data) => {
        if(productId) return find(data, {ProductID: productId});
        return data;
    }
});

export const useAddAgreementMutation = (queryClient, customerId) => useMutation((productId) => API.post('portalApi', `/customers/${customerId}/licenseAgreements`, {
    body: {
        ProductID: productId,
        ActiveLicenses: 0,
        ProvisionedLicenses: 0
    }
}), {
    onSuccess: () => queryClient.invalidateQueries(['licenseAgreements', customerId])
});

export const useCustomerLicenses = (customerId, productId) => useQuery(['licenses', customerId], () => API.get('portalApi', `/customers/${customerId}/licenses`), {
    select: (data) => {
        if(productId) return filter(data, {ProductID: productId}) || [];
        return data;
    },
    ...queryOptions
});