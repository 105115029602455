import React from 'react';
import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Placeholder,
    Flex,
    Button,
    Text,
    Link
  } from '@aws-amplify/ui-react';
import { FaEdit, FaTrash, FaExternalLinkAlt } from 'react-icons/fa'
import { API } from 'aws-amplify';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import PageContent from '../components/PageContent';
import { useProducts } from '../state/ProductQueries';
import { useMutation, useQueryClient } from 'react-query';
import LoadingButton from '../components/LoadingButton';

const ProductsPage = () => {
    const queryClient = useQueryClient();
    const { isLoading: productsLoading, data: products } = useProducts();
    const { mutateAsync: deleteProduct } = useMutation((product) => API.del('portalApi', `/products/${product.ID}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('products');
        }
    });

    const navigate = useNavigate();
    
    return (
        <PageContent
            title="Product List"
            headerActions={<Button size="small" variation="primary" onClick={() => navigate('/portal/products/new')}>Add Product</Button>}
        >
            <Flex direction="column">
                <Placeholder isLoaded={!productsLoading} size="large" />
                <Placeholder isLoaded={!productsLoading} size="large" />
                <Placeholder isLoaded={!productsLoading} size="large" />

                {!!products && products.length && 
                
                    <Table
                        size="small"
                        highlightOnHover={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Name</TableCell>
                                <TableCell as="th">Family</TableCell>
                                <TableCell as="th">SKU</TableCell>
                                <TableCell as="th">Licenses</TableCell>
                                <TableCell as="th">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((product) => 
                                <TableRow key={product.ID}> 
                                    <TableCell>{product.Name}</TableCell>
                                    <TableCell>{product.Family}</TableCell>
                                    <TableCell>{product.SKU.toUpperCase()}</TableCell>
                                    <TableCell><Link>View Licenses <FaExternalLinkAlt/> </Link></TableCell>
                                    <TableCell>
                                        <Text as="span" fontSize="24px">
                                            <Flex>
                                                <Link as={ReactRouterLink} to={product.ID}><Button variation="link" ><FaEdit/></Button></Link>
                                                <LoadingButton onClick={() => deleteProduct(product)} variation='link'><FaTrash /></LoadingButton>
                                            </Flex>
                                        </Text>
                                    </TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                }
            </Flex>
        </PageContent>
    );
}

export default ProductsPage;