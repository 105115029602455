import { Flex, View } from '@aws-amplify/ui-react';
import React, { Fragment } from 'react';

const Dialog = ({ show, handleClose, children }) => {
    if (!show) {
        return <></>;
    }

    return (
        <div className='dialog-pane' onClick={() => handleClose()} >
            <Flex direction='column' height='100%' justifyContent='center' alignItems='center'>
                <View className='dialog-content' onClick={(e) => e.stopPropagation()}>
                    {children}
                </View>
            </Flex>
        </div>
    );
}

export default Dialog;