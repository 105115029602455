import {
    Card,
    Button,
    CheckboxField,
    Divider,
    Flex,
    Placeholder,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Text,
    TextField,
    Heading,
    SelectField,
    TextAreaField
} from '@aws-amplify/ui-react';
import { FaCopy } from 'react-icons/fa';
import { FaTrash, FaSearch } from 'react-icons/fa'
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import PageContent from '../components/PageContent';
import Dialog from '../components/Dialog';
import { API } from 'aws-amplify';
import LoadingButton from '../components/LoadingButton';
import { useProducts } from '../state/ProductQueries';
import { useCustomerLicenses, useCustomers, useLicenseAgreements } from '../state/CustomerQueries';
import { find } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

const CustomerLicenses = () => {
    const queryClient = useQueryClient();
    const [mac, setMac] = useState('');
    const [expiration, setExpiration] = useState('none');
    const [licenseType, setLicenseType] = useState('demo');
    const [libraryVersion, setLibraryVersion] = useState('1.0.0');
    const [floating, setFloating] = useState(false);
    const [licenseAmt, setLicenseAmt] = useState(0);
    const [shownLicense, setShownLicense] = useState({});

    useEffect(() => {
        if(licenseType === 'ent') {
            setFloating(true);
            setExpiration('none');
        }
    }, [licenseType]);
    
    const { productId, customerId } = useParams();
    const {data: products, isLoading: productsLoading} = useProducts();

    const product = find(products, {ID: productId}) || {};
    const { isLoading: customersLoading, data: customer } = useCustomers(customerId)
    
    const {data: licenses, isLoading: licensesLoading} = useCustomerLicenses(customerId, productId);
    const { data: agreement, isLoading: agreementLoading } = useLicenseAgreements(customerId, productId);

    const remainingLicenses = agreement ? agreement.ProvisionedLicenses - agreement.ActiveLicenses : 0;

    const {mutateAsync: provisionLicenses} = useMutation(() => API.put('portalApi', `/customers/${customerId}/licenseAgreements/${productId}/provision`, {body: {number: parseInt(licenseAmt)}}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['licenseAgreements', customerId]);
            setLicenseAmt(0);
        }
    });

    const {mutateAsync: assignLicense} = useMutation(() => API.post('portalApi', `/customers/${customerId}/licenseAgreements/${productId}/licenses`, {body: {
        MAC: mac,
        Floating: floating,
        LicenseType: licenseType,
        CustomerName: customer && customer.Name,
        LibraryVersion: libraryVersion,
        ProductName: product.Name
    }}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['licenses', customerId]);
            setMac('');
        }
    })

    const {mutateAsync: deleteLicense} = useMutation((license) => API.del('portalApi', `/customers/${customerId}/licenseAgreements/${productId}/licenses/${license.ID}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(['licenses', customerId]);
            queryClient.invalidateQueries(['licenseAgreements', customerId]);
        }
    });

    if(productsLoading || customersLoading || licensesLoading || agreementLoading) {
        return (
            <Flex direction="column">
                <Placeholder />
                <Placeholder />
                <Placeholder />
                <Placeholder />
            </Flex>
        );
    }

    return (
        <>
        <PageContent 
            title={product.Name} 
            subtitle={product.SKU.toUpperCase()}
            breadcrumbs={[{ name: 'Customers', path: '../../customers' },{name: customer.Name, path: `../../customers/${customer.ID}`}]}
            headerActions={
                <Flex>
                    <TextField labelHidden={true} label='licenseAmount' type="number" value={licenseAmt} onChange={(e) => setLicenseAmt(e.target.value)} />
                    <LoadingButton variation="primary" onClick={provisionLicenses}>Add License</LoadingButton>
                </Flex>
            }
        >
            <Flex>
                <Flex flex={3} direction="column">
                <Table size="small" highlightOnHover={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">License ID</TableCell>
                            <TableCell as="th">MAC Address</TableCell>
                            <TableCell as="th">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenses.map(lic => 
                            <TableRow key={lic.ID}>
                                <TableCell>{lic.ID}</TableCell>
                                <TableCell>{lic.Floating ? 'Floating' : lic.MAC}</TableCell>
                                <TableCell>
                                    <Flex>
                                        <Button size="small" variation="link" onClick={() => setShownLicense(lic)}><FaSearch/>&nbsp;View</Button>
                                        <LoadingButton size="small" variation="link" onClick={() => deleteLicense(lic)}><FaTrash/> Delete</LoadingButton>
                                    </Flex>
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
                </Flex>
                <Divider size="small" orientation="vertical" />
                <Flex flex={2} direction="column">
                    <Flex alignItems='start' justifyContent='space-between'>
                        <div style={{width: '100%', flex: 2}}>
                            <TextField disabled={!remainingLicenses || floating} label="MAC Address" placeholder="Enter MAC address" value={mac} onChange={(e) => setMac(e.target.value)} />
                        </div>
                        <Flex flex={1}>
                        <CheckboxField disabled={licenseType==='ent'} flex={2} label="Floating License" name="floating" value="yes" checked={floating} onChange={(e) => setFloating(e.target.checked)}></CheckboxField>
                        </Flex>
                    </Flex>

                    <SelectField flex={1} label="License Type" size='small' value={licenseType} onChange={(e) => setLicenseType(e.target.value)}>
                        <option value='demo'>Demo</option>
                        <option value='pro'>Professional</option>
                        <option value='ent'>Enterprise</option>
                    </SelectField>

                    <TextField disabled={!remainingLicenses} label="Library Version" placeholder="Enter Library version" value={libraryVersion} onChange={(e) => setLibraryVersion(e.target.value)} />

                    <SelectField disabled={licenseType==='ent'} flex={1} label="Expiration" size='small' value={expiration} onChange={(e) => setExpiration(e.target.value)}>
                        <option value='none'>None</option>
                        <option value='30d'>30 Days</option>
                        <option value='60d'>60 Days</option>
                    </SelectField>

                    <LoadingButton disabled={!remainingLicenses} variation="primary" onClick={assignLicense}>Activate License</LoadingButton>
                    <Text alignSelf="flex-end">{remainingLicenses} Licenses Remaining</Text>
                </Flex>
            </Flex>
        </PageContent>
        <Dialog show={!!shownLicense.ID}>
            <Card variation='outlined' width='100%'>
                <Flex direction='column'>
                    <Heading level={4}>License Key</Heading>
                    <Flex justifyContent='space-between'>
                        <Heading level={6}>{shownLicense.ID}</Heading>
                        <Button size="small" variation="link" onClick={() => navigator.clipboard.writeText(shownLicense.License)}><FaCopy/> Copy License</Button>
                    </Flex>
                    <TextAreaField label='license' labelHidden={true} rows={6} defaultValue={shownLicense.License} isReadOnly={true} fontFamily='monospace'/>
                    <Flex justifyContent='flex-end'>
                        <Button onClick={() => setShownLicense({})} variation='primary'>Close</Button>
                    </Flex>
                </Flex>
            </Card>
        </Dialog>
        </>
    )
}

export default CustomerLicenses;