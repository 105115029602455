import React, { useState } from "react";
import { Button, Divider, Flex, TextField } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import PageContent from "../components/PageContent";
import { keys } from "lodash";
import { useCustomers } from "../state/CustomerQueries";
import { useMutation, useQueryClient } from "react-query";
import LoadingButton from "../components/LoadingButton";
import { API } from "aws-amplify";

const NewCustomerPage = () => {
    const queryClient = useQueryClient();
    const [ errors, seterrors ] = useState({});
    const { customerId } = useParams();
    const navigate = useNavigate();

    const customer = useCustomers(customerId);

    const [newCustomer, setCustomer] = useState(customer || {
        Name: '',
        Status: 'Current',
        Contact: '',
        ContactEmail: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zip: ''
    });    

    const { mutateAsync: updateCustomer } = useMutation((customer) => API.put('portalApi', `/customers/${customer.ID}`, {body: customer}), {
        onSuccess: () => {
            queryClient.invalidateQueries('customers');
            navigate('/customers', { replace: true });
        }
    });

    const { mutateAsync: createCustomer } = useMutation((customer) => API.post('portalApi', `/customers`, {body: customer}), {
        onSuccess: () => {
            queryClient.invalidateQueries('customers');
            navigate('/customers', { replace: true });
        }
    });

    const submit = async () => {
        let validationErrors = {};
        if(!newCustomer.Name) validationErrors.name = true;
        if(!newCustomer.Contact) validationErrors.contact = true;
        if(!newCustomer.ContactEmail) validationErrors.email = true;
        if(!newCustomer.Address1) validationErrors.addr1 = true;
        if(!newCustomer.City) validationErrors.city = true;
        if(!newCustomer.State) validationErrors.state = true;
        if(!newCustomer.Zip) validationErrors.zip = true;
        seterrors(validationErrors);

        if(keys(validationErrors).length) return;

        if(!newCustomer.Status) 
            newCustomer['Status'] = 'Current'

        if(newCustomer.ID) {
            return await updateCustomer(newCustomer);
        }
        
        await createCustomer(newCustomer);
    }

    return (
        <PageContent
            title={!!customerId ? 'Edit Customer' : 'Add Customer'} 
            breadcrumbs={[{ name: 'Customerrors', path: '/customerrors' }]}
        >
            <Flex direction="column" as='form' >
                <Flex>
                    <Flex direction="column" flex={1}>
                        <TextField isRequired hasError={errors.name} label="Business Name*" placeholder="Enter customer name" value={newCustomer.Name} onChange={(e) => setCustomer({...newCustomer, Name: e.target.value})}/>
                        <TextField isRequired hasError={errors.contact} label="Contact Name*" placeholder="Enter contact name" value={newCustomer.Contact} onChange={(e) => setCustomer({...newCustomer, Contact: e.target.value})}/>
                        <TextField isRequired hasError={errors.email} label="Contact Email*" type="email" placeholder="Enter contact email" value={newCustomer.ContactEmail} onChange={(e) => setCustomer({...newCustomer, ContactEmail: e.target.value})}/>
                    </Flex>
                    <Divider size="small" orientation="vertical" />
                    <Flex direction="column" flex={1}>
                        <TextField isRequired hasError={errors.addr1} label="Address 1*" placeholder="Enter address 1" value={newCustomer.Address1} onChange={(e) => setCustomer({...newCustomer, Address1: e.target.value})}/>
                        <TextField label="Address 2" placeholder="Enter address 2" value={newCustomer.Address2} onChange={(e) => setCustomer({...newCustomer, Address2: e.target.value})}/>
                        <Flex>
                            <TextField isRequired hasError={errors.city} label="City*" placeholder="Enter city" value={newCustomer.City} onChange={(e) => setCustomer({...newCustomer, City: e.target.value})}/>
                            <TextField isRequired hasError={errors.state} label="State*" placeholder="Enter state" value={newCustomer.State} onChange={(e) => setCustomer({...newCustomer, State: e.target.value})}/>
                            <TextField isRequired hasError={errors.zip} type="number" pattern="[0-9]{5}"label="ZIP*" placeholder="Enter zip" value={newCustomer.Zip} onChange={(e) => {setCustomer({...newCustomer, Zip: e.target.value})}}/>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex >
                        <LoadingButton onClick={submit} variation="primary">
                            Submit
                        </LoadingButton>
                        <Button  onClick={() => navigate(-1)}>Cancel</Button>
                    </Flex>
            </Flex>
        </PageContent>
    );
}

export default NewCustomerPage;