import { Heading } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CgMenuBoxed, } from "react-icons/cg"
import { RiSdCardMiniLine } from "react-icons/ri"
import { HiChip } from "react-icons/hi"
import { FaTools } from "react-icons/fa"
import { GiMeshNetwork } from "react-icons/gi"
import { GrServices } from "react-icons/gr"
const style = {
    width: '100%',
    height: '25%',
    textAlign: 'center',
    margin: '20px 0 20px 0'
}

const iconStyle = {
    color: '#645FAA'
}

const ServiceDetail = ({ name, desc }) => {
    const [icon, setIcon] = useState(<></>)

    useEffect(() => {
        switch (name) {
            case "Chassis Manager":
                setIcon(<CgMenuBoxed size="50px" marginTop='auto' />)
                break;
            case "Plug-in Card":
                setIcon(<RiSdCardMiniLine size="50px" marginTop='auto' />)
                break;
            case "Generic Module":
                setIcon(<HiChip size="50px" marginTop='auto' />)
                break;
            case "Custom Hardware Integration":
                setIcon(<FaTools size="50px" marginTop='auto' />)
                break;
            case "Network Configuration Management":
                setIcon(<GiMeshNetwork size="50px" marginTop="auto" />)
                break;
            case "Services":
                setIcon(<GrServices size="50px" marginTop="auto" />)
                break;
        }
    }, [])
    return (
        <Flex direction="row" alignContent="center" style={style}>
            <div style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto' }}>
                {icon}
            </div>
            <div style={{ margin: "auto auto auto 0", textAlign: "left" }}>
                <Heading className="avenir" color="#0072BC" level={4}>{name}</Heading>
                <Heading className="avenir" level={8}>
                    {desc}
                </Heading>
            </div>
        </Flex >
    )
}

export default ServiceDetail;