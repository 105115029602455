import React, { Fragment, useState } from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import UserDetails from './UserDetails';
import UsersPage from './Users';

const UsersIndex = () => {
    const [user, setUser] = useState({});
    let navigate = useNavigate();

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<UsersPage onUserSelected={(u) => {navigate(`${u.Username}`)}}/>} />
                <Route path=":userId" element={<UserDetails user={user} setUser={setUser}/>}/>
            </Routes>

            <Outlet context={[user, setUser]} />
        </Fragment>
    )
}

export default UsersIndex;