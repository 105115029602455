import React, { useState } from "react";
import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import PageContent from "../components/PageContent";
import { useProducts } from "../state/ProductQueries";
import LoadingButton from "../components/LoadingButton";
import { useMutation, useQueryClient } from "react-query";
import { API } from "aws-amplify";

const NewProductPage = () => {
    const queryClient = useQueryClient();
    const { productId } = useParams();
    const { data: product } = useProducts(productId);
    
    const navigate = useNavigate();

    const [newProduct, setProduct] = useState(product.ID ? product : {
        Name: '',
        Family: '',
        SKU: ''
    });

    const { mutateAsync: updateProduct } = useMutation((product) => API.put('portalApi', `/products/${product.ID}`, {body: product}), {
        onSuccess: () => {
            queryClient.invalidateQueries('products');
            navigate('/portal/products', { replace: true });
        }
    });

    const { mutateAsync: createProduct } = useMutation((product) => API.post('portalApi', `/products`, {body: product}), {
        onSuccess: () => {
            queryClient.invalidateQueries('products');
            navigate('/portal/products', { replace: true });
        }
    });

    const submit = async () => {
        if(newProduct.ID) {
            return await updateProduct(newProduct);
        }
        
        return await createProduct(newProduct);
    }

    return (
        <PageContent
            title={!!productId ? 'Edit Product' : 'Add Product'} 
            breadcrumbs={[{ name: 'Products', path: '../../products' }]}
        >
            <Flex direction="column">
                <TextField label="Name" placeholder="Enter product name" value={newProduct.Name} onChange={(e) => setProduct({...newProduct, Name: e.target.value})}/>
                <TextField label="Product Family" placeholder="Enter product family" value={newProduct.Family} onChange={(e) => setProduct({...newProduct, Family: e.target.value})}/>
                <TextField label="SKU" placeholder="Enter SKU" value={newProduct.SKU} onChange={(e) => setProduct({...newProduct, SKU: e.target.value})}/>
                <Flex >
                    <LoadingButton variation="primary" onClick={submit}>
                        Submit
                    </LoadingButton>
                    <Button onClick={() => navigate(-1)}>Cancel</Button>
                </Flex>
            </Flex>
        </PageContent>
    );
}

export default NewProductPage;