import { useQuery } from "react-query";
import { API } from 'aws-amplify';
import { find } from "lodash";

const queryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 60000,
    refetchOnMount: true
};

export const useProducts = (productId) => useQuery('products', () => API.get('portalApi', '/products'), {
    ...queryOptions,
    select: (data) => {
        if(productId) return find(data, {ID: productId});
        return data;
    }
});